// extracted by mini-css-extract-plugin
export var bgGrey100 = "ContactForm-module--bg-grey-100--295db";
export var bgGrey150 = "ContactForm-module--bg-grey-150--6e953";
export var bgGrey200 = "ContactForm-module--bg-grey-200--eb397";
export var bgGrey300 = "ContactForm-module--bg-grey-300--0bc46";
export var bgGrey400 = "ContactForm-module--bg-grey-400--11e07";
export var bgGrey500 = "ContactForm-module--bg-grey-500--590d3";
export var bgGrey600 = "ContactForm-module--bg-grey-600--ffa57";
export var bgGrey700 = "ContactForm-module--bg-grey-700--da997";
export var bgGrey800 = "ContactForm-module--bg-grey-800--69029";
export var bgGrey900 = "ContactForm-module--bg-grey-900--79746";
export var formStyles = "ContactForm-module--formStyles--ac93b";
export var textGrey100 = "ContactForm-module--text-grey-100--35b8c";
export var textGrey150 = "ContactForm-module--text-grey-150--88b9b";
export var textGrey200 = "ContactForm-module--text-grey-200--e4ce1";
export var textGrey300 = "ContactForm-module--text-grey-300--3d394";
export var textGrey400 = "ContactForm-module--text-grey-400--888a4";
export var textGrey500 = "ContactForm-module--text-grey-500--d06bd";
export var textGrey600 = "ContactForm-module--text-grey-600--785d5";
export var textGrey700 = "ContactForm-module--text-grey-700--564b5";
export var textGrey800 = "ContactForm-module--text-grey-800--5463b";
export var textGrey900 = "ContactForm-module--text-grey-900--33cc2";