import React from "react"
import { Container } from "react-bootstrap"
import { Section, SectionHeading } from "../../UI/Common"
import SimpleForm from "../../../templates/ProductUI/SimpleForm"
import * as style from "./ContactForm.module.scss"
import { dataLayerUpdate } from "../../../api/dataLayer/gaDataLayer"

const ContactForm = ({ sectionHeading, paragraph, config, location }) => {
  const formId = "CONTACT_US"
  const formType = "CONTACT_FORM"
  dataLayerUpdate("showForm", {formType, location, formId})
  const { padding } = config || {}
  return (
    <Section padding={padding}>
      <Container className="text-center">
        {paragraph && <p className="pb-lg-3 text-left">{paragraph}</p>}
        <SimpleForm
          formId={formId}
          formType={formType}
          location={location}
          formUrl={process.env.WP_CONTACT_FORM}
          textAreaLabel="What can we do for you?"
          className={style.formStyles}
        />
      </Container>
    </Section>
  )
}

export default ContactForm
