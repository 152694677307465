import { graphql } from "gatsby"
import React from "react"
import ContactForm from "./ContactForm"
export const fragment = graphql`
  fragment ContactFormFragment on WpPage_Flexlayouts_FlexibleLayouts_ContactForm {
    sectionHeading
    paragraph
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFContactForm = ({
  sectionHeading,
  paragraph,
  config,
  location,
}) => {
  return (
    <ContactForm
      sectionHeading={sectionHeading}
      paragraph={paragraph}
      config={config}
      location={location}
    />
  )
}
